.report-header {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 4px;
}

.comments {
  width: 100%;
}

.table-compressed {
  font-size: 10px;
  width: 90%;
}

.statistics-table {
  display: flex;
  justify-content: center;
}

.statistics-table > div {
  width: 80%;
}

.edit-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 15px;
}

.chart-section-container {
  margin-left: 2%;
  margin-right: 2%;
  max-width: none;
}

.results-table-container {
  display: flex;
}

.results-entry {
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 0.3rem;
}

.control-limit-entry {
  border: 1px solid #dee2e6;
  padding: 0.3rem;
  font-weight: bold;
  padding: 8px;
  width: 33%;
}

.control-limit-container {
  margin-bottom: 16px;
}

.control-limit-entry-container .control-limit-entry {
  font-weight: 400;
  padding: 8px;
}

.control-limit-entry-container .control-limit-entry-value {
  font-weight: 400;
}

.control-limit-entry-select {
  border: 1px solid #dee2e6;
  padding: 9px;
  padding-bottom: 10px;
  padding-right: 16px;
  width: 33%;
}

.control-limit-entry-value {
  width: 34%;
  padding: 8px;
  border: 1px solid #dee2e6;
  font-weight: bold;
}

.results-entry-container input {
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  padding: 0.3rem;
  width: 100%;
}

.results-entry-footer {
  border-top: 1px solid #dee2e6;
  padding: 0.3rem;
}

.checkbox-buttons-container,
.checkbox-buttons {
  display: flex;
  justify-content: center;
}

.checkbox-buttons {
  margin-left: 6px;
  margin-right: 6px;
}

.checkbox-buttons input {
  margin-left: 6px;
}

.form-check {
  margin-left: 5px;
  margin-right: 5px;
}

.chart-row-container {
  justify-content: center;
  width: 100%;
}

.results-table-class {
  display: flex;
}

.results-entry-table-container-full {
  display: flex;
}

.render-results-container {
  display: flex;
}

.render-results-table-container {
  display: flex;
}

.chart-section-overall-container {
  display: flex;
}

.results-chart-container {
  width: 50%;
}

.charts-master-container {
  width: 50%;
}

.render-results-entry-table-container {
  display: flex;
  flex-direction: column;
  width: 120px;
}

.modal-90w {
  width: 90%;
  min-width: 80%;
  max-width: 100%;
}

.print-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.print-button-container Button {
  width: 70px;
}

.archive-alert {
  margin-left: 20%;
  margin-right: 20%;
}

.archive-toggle-container {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.ten-ninety-date-container {
  display: flex;
}

.ten-ninety-date-container input {
  min-width: 180px !important;
  font-weight: 500;
}

.create-account-container {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.signin-button-container {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
