.instrument-management {
  .title {
    text-align: center;
  }
  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
    a {
      &:after {
        content: '';
        height: 3px;
        width: 100%;
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        border-radius: 3px;
      }
      font-size: 16px;
      &.active {
        font-weight: bold;
        &:after {
          background-color: #1976d2;
        }
      }
    }
  }
  .top-actions {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 16px;
    margin-bottom: 20px;
  }
  .filter {
    > p {
      margin: 0;
    }
  }
  .grid-view {
    margin-top: 20px;
    .comments {
      padding: 8px 0;
      p {
        &:empty {
          display: none;
        }
        margin: 0 0 12px;
        &:last-child {
          margin: 0;
        }
      }
    }
    .inner-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      margin-bottom: 10px;
    }
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
  }
  .event-details {
    .event-information {
      max-width: 9999px;
    }
    .event-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }
    h2 {
      font-size: 24px;
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    table {
      width: 100%;
      .table-links {
        display: flex;
        align-items: center;
        justify-content: right;
        gap: 16px;
      }
    }
    .button-below {
      margin-top: 0px;
    }
  }
  .settings-wrapper {
    display: flex;
    margin-left: -20px;
    .settings-sidebar {
      width: 230px;
      padding-right: 16px;
    }
    .settings-content {
      width: calc(100% - 230px);
    }
  }
  .define-categories {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
  }
}
.react-datepicker__input-container {
  input {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    height: 38px;
    padding: 0 12px;
    &:focus-visible {
      outline: none;
    }
    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
  }
}
.event-number {
  padding-bottom: 16px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.flex-end {
  align-items: flex-end;
}
