.assessments-table-container {
  margin-left: 1%;
  margin-right: 1%;
}

.page-three-container {
  font-size: 13px;
}

.periodic-buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}
